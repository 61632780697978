import axios from 'axios';
import { MessageBox, Message } from 'element-ui';
import store from '@/store';
import { getToken } from '@/utils/auth';
import qs from 'qs';

// create an axios instance
var service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  // api 的 base_url
  // withCredentials: true, // 跨域请求时发送 cookies
  timeout: 20000 // request timeout
});

// request interceptor
service.interceptors.request.use(function (config) {
  // Do something before request is sent
  if (store.getters.token) {
    // 让每个请求携带token-- ['X-Token']为自定义key 请根据实际情况自行修改
    config.headers['Authorization'] = 'Bearer ' + getToken();
  }
  return config;
}, function (error) {
  // Do something with request error
  console.log(error); // for debug
  Promise.reject(error);
});

// response interceptor
service.interceptors.response.use(
/**
 * If you want to get information such as headers or status
 * Please return  response => response
 */
/**
 * 下面的注释为通过在response里，自定义code来标示请求状态
 * 当code返回如下情况则说明权限有问题，登出并返回到登录页
 * 如想通过 XMLHttpRequest 来状态码标识 逻辑可写在下面error中
 * 以下代码均为样例，请结合自生需求加以修改，若不需要，则可删除
 */
function (response) {
  /**
   * code为非200是抛错 可结合自己业务进行修改
   */
  var res = response.data;
  if (res.code !== 200) {
    // 500:操作失败 404参数校验失败 401暂未登录或token过期 403没有相关权限
    if (res.code === 401) {
      // 请自行在引入 MessageBox
      // import { Message, MessageBox } from 'element-ui'
      MessageBox.confirm('你已被登出，可以取消继续留在该页面，或者重新登录', '确定登出', {
        confirmButtonText: '重新登录',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        store.dispatch('user/resetToken').then(function () {
          localStorage.removeItem('Admin-Token');
          location.reload(); // 为了重新实例化vue-router对象 避免bug
        });
      });
    } else if (res.code === 403) {
      Message({
        message: '暂无权限',
        type: 'error',
        duration: 5 * 1000
      });
    } else {
      Message({
        message: res.message,
        type: 'error',
        duration: 5 * 1000
      });
    }
    return Promise.reject('error');
  } else {
    return res;
  }
}, function (error) {
  console.log('err' + error); // for debug
  Message({
    message: error.message,
    type: 'error',
    duration: 5 * 1000
  });
  return Promise.reject(error);
});
export default service;