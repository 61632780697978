import { getWebInfo } from '@/api/system';
var state = {
  webName: '',
  logo: '',
  brandList: []
};
var mutations = {
  SET_WEB_NAME: function SET_WEB_NAME(state, webName) {
    state.webName = webName;
  },
  SET_LOGO: function SET_LOGO(state, logo) {
    state.logo = logo;
  },
  SET_BRAND_LIST: function SET_BRAND_LIST(state, brandList) {
    state.brandList = brandList;
  }
};
var actions = {
  // 获取系统信息
  /* getWebInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      getWebInfo().then(response => {
        const data = response.data
        commit('SET_WEB_NAME', data.webName)
        commit('SET_LOGO', data.logo)
        commit('SET_BRAND_LIST', data.brandList)
        //commit('SET_INTRODUCTION', data.introduction)
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  } */
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};