var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-client" },
    [
      _c("div", { staticClass: "header-div" }, [
        _c("div", { staticClass: "inside-content row header" }, [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "header-right" },
            [
              !_vm.username
                ? _c(
                    "div",
                    { staticClass: "login-button", on: { click: _vm.goLogin } },
                    [_vm._v("登录")]
                  )
                : _c(
                    "el-dropdown",
                    { staticClass: "login-info" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "row",
                          staticStyle: { "align-items": "center" },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "head_img" },
                            [
                              _c("el-avatar", {
                                attrs: {
                                  fit: "cover",
                                  shape: "circle",
                                  size: 45,
                                  src: _vm.head_img,
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "head_username" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.username) +
                                "\n            "
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-dropdown-menu",
                        { attrs: { slot: "dropdown" }, slot: "dropdown" },
                        [
                          _c("el-dropdown-item", [
                            _c("span", { on: { click: _vm.logout } }, [
                              _vm._v("退出登录"),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "content-div" }, [
        _c("div", { staticClass: "inside-content " }, [
          _c(
            "section",
            [
              _c(
                "transition",
                { attrs: { name: "fade-transform", mode: "out-in" } },
                [
                  _c(
                    "keep-alive",
                    [
                      _c("router-view", {
                        key: _vm.key,
                        on: { login: _vm.goLogin },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("el-backtop", { attrs: { target: ".content-div" } }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header-left" }, [
      _c("img", {
        staticStyle: { height: "60px" },
        attrs: { src: require("@/assets/logo.png") },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }