import _objectSpread from "/root/workspace/tk-shop_1BXA/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import Cookies from 'js-cookie';
import elementEnLocale from 'element-ui/lib/locale/lang/en'; // element-ui lang
import elementZhLocale from 'element-ui/lib/locale/lang/zh-CN'; // element-ui lang
import elementEsLocale from 'element-ui/lib/locale/lang/es'; // element-ui lang
import enLocale from "./en";
import zhLocale from "./zh";
import esLocale from "./es";
Vue.use(VueI18n);
var messages = {
  en: _objectSpread(_objectSpread({}, enLocale), elementEnLocale),
  zh: _objectSpread(_objectSpread({}, zhLocale), elementZhLocale),
  es: _objectSpread(_objectSpread({}, esLocale), elementEsLocale)
};
var i18n = new VueI18n({
  // set locale
  // options: en | zh | es
  locale: Cookies.get('language') || 'zh',
  // set locale messages
  messages: messages
});
export default i18n;