import _objectSpread from "/root/workspace/tk-shop_1BXA/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import RightPanel from "@/components/RightPanel";
import { Navbar, Sidebar, AppMain, TagsView, Settings
// Message
} from "./components";
import ResizeMixin from "./mixin/ResizeHandler";
import { mapState } from "vuex";
import checkPermission from "@/utils/permission"; // 权限判断函数

export default {
  name: "Layout",
  components: {
    RightPanel: RightPanel,
    Navbar: Navbar,
    Sidebar: Sidebar,
    AppMain: AppMain,
    TagsView: TagsView,
    Settings: Settings
    // Message
  },
  mixins: [ResizeMixin],
  computed: _objectSpread(_objectSpread({}, mapState({
    sidebar: function sidebar(state) {
      return state.app.sidebar;
    },
    device: function device(state) {
      return state.app.device;
    },
    showSettings: function showSettings(state) {
      return state.settings.showSettings;
    },
    needTagsView: function needTagsView(state) {
      return state.settings.tagsView;
    },
    fixedHeader: function fixedHeader(state) {
      return state.settings.fixedHeader;
    }
  })), {}, {
    classObj: function classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === "mobile"
      };
    }
  }),
  methods: {
    checkPermission: checkPermission,
    handleClickOutside: function handleClickOutside() {
      this.$store.dispatch("app/closeSideBar", {
        withoutAnimation: false
      });
    }
  },
  created: function created() {
    var self = this;
    if (!this.checkPermission(["admin"])) {
      var _self = this;
      _self.socket.creatSocket();
      _self.$nextTick(function () {
        _self.socket.setDom(_self.$refs.immessage);
      });
    }
  }
};