import Vue from 'vue';
import Router from 'vue-router';
Vue.use(Router);

/* Layout */
import Layout from '@/layout';

/* Router Modules */
import logRouter from "./modules/log";
import shopRouter from "./modules/shop";
import orgRouter from "./modules/org";
import smallRouter from "@/router/modules/small";
import fundRouter from "@/router/modules/fund";
import accountRouter from "@/router/modules/account";
import warehouse from "@/router/modules/warehouse";
import logisticsRouter from "@/router/modules/logistics";
import expertlibrary from "@/router/modules/expertlibrary";
import freightcountRouter from "@/router/modules/freightcount";
import sysmanageRouter from "@/router/modules/sysmanage";
import clientRouter from "@/router/modules/client";

/** note: sub-menu only appear when children.length>=1
 *  detail see  https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 **/

/**
* hidden: true                   if `hidden:true` will not show in the sidebar(default is false)
* alwaysShow: true               if set true, will always show the root menu, whatever its child routes length
*                                if not set alwaysShow, only more than one route under the children
*                                it will becomes nested mode, otherwise not show the root menu
* redirect: noredirect           if `redirect:noredirect` will no redirect in the breadcrumb
* name:'router-name'             the name is used by <keep-alive> (must set!!!)
* meta : {
    roles: ['admin','editor']    will control the page roles (you can set multiple roles)
    title: 'title'               the name show in sub-menu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    noCache: true                if true, the page will no be cached(default is false)
    breadcrumb: false            if false, the item will hidden in breadcrumb(default is true)
    affix: true                  if true, the tag will affix in the tags-view
  }
**/
export var constantRoutes = [{
  path: '/redirect',
  component: Layout,
  hidden: true,
  children: [{
    path: '/redirect/:path*',
    component: function component() {
      return import('@/views/redirect/index');
    }
  }]
}, {
  path: '/login',
  component: function component() {
    return import('@/views/login/index');
  },
  hidden: true
}, {
  path: '/register',
  component: function component() {
    return import('@/views/login/register');
  },
  hidden: true
}, {
  path: '/invite',
  component: function component() {
    return import('@/views/login/invite');
  },
  name: 'Invite',
  meta: {
    title: '注册子账号'
  },
  hidden: true
}, {
  path: '/genOrder',
  hidden: true,
  component: function component() {
    return import('@/views/genOrder/order/index');
  },
  //index metalsindex
  name: 'genOrder',
  meta: {
    title: '生成订单'
  }
}, {
  path: '/show',
  component: function component() {
    return import('@/views/login/show');
  },
  hidden: true
}, {
  path: '/join',
  component: function component() {
    return import('@/views/shop/join/index');
  },
  hidden: true
}, {
  path: '/auth-redirect',
  component: function component() {
    return import('@/views/login/authredirect');
  },
  hidden: true
}, {
  path: '/404',
  component: function component() {
    return import('@/views/errorPage/404');
  },
  hidden: true
}, {
  path: '/401',
  component: function component() {
    return import('@/views/errorPage/401');
  },
  hidden: true
}, {
  path: '/info',
  component: function component() {
    return import('@/views/org/account/info');
  },
  name: 'accountInfo',
  meta: {
    title: '个人中心'
  },
  hidden: true
}, {
  path: '',
  component: Layout,
  redirect: 'dashboard',
  children: [{
    path: 'dashboard',
    component: function component() {
      return import('@/views/dashboard/metalsindex');
    },
    //index metalsindex
    name: 'Dashboard',
    meta: {
      title: '控制台',
      icon: 'kongzhitai',
      noCache: true,
      affix: true
    }
  }]
}, clientRouter];
export var asyncRoutes = [shopRouter, orgRouter, logRouter, smallRouter, fundRouter, accountRouter, warehouse, logisticsRouter, expertlibrary, freightcountRouter, sysmanageRouter, {
  path: '*',
  redirect: '/404',
  hidden: true
}];
var createRouter = function createRouter() {
  return new Router({
    // mode: 'history', // require service support
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};
var router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
export default router;