import "core-js/modules/es6.number.constructor";
import "core-js/modules/es6.regexp.constructor";
import "core-js/modules/es6.set";
import "core-js/modules/es6.string.iterator";
import "core-js/modules/es6.array.from";
import "core-js/modules/es6.regexp.split";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.regexp.match";
import "core-js/modules/es6.regexp.to-string";
import _typeof from "/root/workspace/tk-shop_1BXA/node_modules/@babel/runtime-corejs2/helpers/esm/typeof.js";
import "core-js/modules/es6.regexp.replace";
/**
 * Created by jiachenpan on 16/11/18.
 */
import axios from 'axios';
import { getToken } from '@/utils/auth';
export function exportMethod(data) {
  axios({
    method: data.method,
    url: process.env.VUE_APP_BASE_API + "".concat(data.url).concat(data.params ? '?' + data.params : ''),
    responseType: 'blob',
    headers: {
      'Authorization': 'Bearer ' + getToken()
    }
  }).then(function (res) {
    var link = document.createElement('a');
    var blob = new Blob([res.data], {
      type: 'application/vnd.ms-excel'
    });
    link.style.display = 'none';
    link.href = URL.createObjectURL(blob);

    // link.download = res.headers['content-disposition'] //下载后文件名
    link.download = data.fileName; //下载的文件名
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }).catch(function (error) {
    alert("该时间段没有导出的数据");
  });
}
/*获取周的起止时间*/
export function getWeekStartAndEnd(now, formatStr) {
  if (!now) {
    now = new Date();
  }
  if (!formatStr) {
    formatStr = 'YYYY-MM-DD HH:mm:ss';
  }
  var dayOfWeek = now.getDay();
  var diff = now.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1);
  var weekStart = new Date(now.setDate(diff));
  var weekEnd = new Date(weekStart.getTime() + 6 * 24 * 60 * 60 * 1000);
  weekStart.setHours(0, 0, 0, 0);
  weekEnd.setHours(23, 59, 59, 999);
  return {
    weekStart: format(weekStart, formatStr),
    weekEnd: format(weekEnd, formatStr)
  };
}
/*获取近i天数的连续时间*/
export function getDatesOfLastDays(now, days, formatStr) {
  if (!now) {
    now = new Date();
  }
  if (!formatStr) {
    formatStr = 'YYYY-MM-DD';
  }
  var datesArray = [];
  for (var i = days; i >= 0; i--) {
    var date = new Date(now.getTime() - i * 24 * 60 * 60 * 1000);
    datesArray.push(format(date, formatStr));
  }
  return datesArray;
}
/*获取近30天时间*/
export function getDatesEndAndLastDays(now, days, formatStr) {
  if (!now) {
    now = new Date();
  }
  if (!formatStr) {
    formatStr = 'YYYY-MM-DD';
  }
  var endDate = new Date(now.getFullYear(), now.getMonth(), now.getDate()); // 当前日期
  var startDate = new Date(now.getTime() - days * 24 * 60 * 60 * 1000); // 30天前的日期

  return {
    start: format(startDate, formatStr),
    end: format(endDate, formatStr)
  };
}

/*获取当前月起止时间*/
export function getMonthStartAndEnd(now, formatStr) {
  if (!now) {
    now = new Date();
  }
  if (!formatStr) {
    formatStr = 'YYYY-MM-DD HH:mm:ss';
  }
  var monthStart = new Date(now.getFullYear(), now.getMonth(), 1);
  var monthEnd = new Date(now.getFullYear(), now.getMonth() + 1, 0);
  monthStart.setHours(0, 0, 0, 0);
  monthEnd.setHours(23, 59, 59, 999);
  return {
    monthStart: format(monthStart, formatStr),
    monthEnd: format(monthEnd, formatStr)
  };
}
function padZero(num) {
  return num < 10 ? '0' + num : num;
}
export function format(date, formatStr) {
  return formatStr.replace(/YYYY|MM|DD|HH|mm|ss/g, function (token) {
    switch (token) {
      case 'YYYY':
        return date.getFullYear();
      case 'MM':
        return padZero(date.getMonth() + 1);
      case 'DD':
        return padZero(date.getDate());
      case 'HH':
        return padZero(date.getHours());
      case 'mm':
        return padZero(date.getMinutes());
      case 'ss':
        return padZero(date.getSeconds());
      default:
        return token;
    }
  });
}
export function formatDate(date) {
  var year = date.getFullYear();
  var month = ("0" + (date.getMonth() + 1)).slice(-2); // 月份补0
  var day = ("0" + date.getDate()).slice(-2); // 日补0
  return "".concat(year, "-").concat(month, "-").concat(day);
}
/*获取当前月起止时间*/
export function getMonthStartAndEndDay(date) {
  var year = date.getFullYear();
  var month = date.getMonth(); // 注意：JavaScript中的月份是从0开始的，所以1月是0
  // 获取当月的第一天
  var firstDay = new Date(year, month, 1);
  // 获取当月的最后一天
  var lastDay = new Date(year, month + 1, 0); // 传入下个月的1号减去1天即为本月最后一天
  // 格式化日期为yyyy-mm-dd
  return [formatDate(firstDay), formatDate(lastDay)];
}
export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null;
  }
  if (!time) {
    return '';
  }
  var format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}';
  var issafariBrowser = (/AppleWebKit/.test(navigator.userAgent) || /Safari/.test(navigator.userAgent)) && !/Chrome/.test(navigator.userAgent);
  var date;
  if (_typeof(time) === 'object') {
    date = time;
  } else {
    if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
      time = parseInt(time);
    }
    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000;
    }
    if (issafariBrowser) {
      date = formatUTC(time);
    } else {
      date = new Date(time);
    }
  }
  var formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  };
  var time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, function (result, key) {
    var value = formatObj[key];
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value];
    }
    if (result.length > 0 && value < 10) {
      value = '0' + value;
    }
    return value || 0;
  });
  return time_str;
}
function formatUTC(t) {
  if (t.indexOf('T') != -1) {
    var y, h; //年份,时间
    var regH = /(T| )(\d\d:\d\d:\d\d)(\.\d+)?/; // 校验时间格式
    h = t.match(regH);
    h = h && h[2]; //拿到时分秒
    y = t.slice(0, t.indexOf('T') + 1); //截取年月日
    var x = y.slice(0, -1) + ' ' + h;
    return new Date(x.replace(/\-/g, '/'));
  }
  return new Date(t.replace(/\-/g, '/'));
}
export function formatTime(time, option) {
  if (('' + time).length === 10) {
    time = parseInt(time) * 1000;
  } else {
    time = +time;
  }
  var d = new Date(time);
  var now = Date.now();
  var diff = (now - d) / 1000;
  if (diff < 30) {
    return '刚刚';
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前';
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前';
  } else if (diff < 3600 * 24 * 2) {
    return '1天前';
  }
  if (option) {
    return parseTime(time, option);
  } else {
    return d.getMonth() + 1 + '月' + d.getDate() + '日' + d.getHours() + '时' + d.getMinutes() + '分';
  }
}

// 格式化时间
export function getQueryObject(url) {
  url = url == null ? window.location.href : url;
  var search = url.substring(url.lastIndexOf('?') + 1);
  var obj = {};
  var reg = /([^?&=]+)=([^?&=]*)/g;
  search.replace(reg, function (rs, $1, $2) {
    var name = decodeURIComponent($1);
    var val = decodeURIComponent($2);
    val = String(val);
    obj[name] = val;
    return rs;
  });
  return obj;
}

/**
 * @param {Sting} input value
 * @returns {number} output value
 */
export function byteLength(str) {
  // returns the byte length of an utf8 string
  var s = str.length;
  for (var i = str.length - 1; i >= 0; i--) {
    var code = str.charCodeAt(i);
    if (code > 0x7f && code <= 0x7ff) s++;else if (code > 0x7ff && code <= 0xffff) s += 2;
    if (code >= 0xDC00 && code <= 0xDFFF) i--;
  }
  return s;
}
export function cleanArray(actual) {
  var newArray = [];
  for (var i = 0; i < actual.length; i++) {
    if (actual[i]) {
      newArray.push(actual[i]);
    }
  }
  return newArray;
}
export function param(json) {
  if (!json) return '';
  return cleanArray(Object.keys(json).map(function (key) {
    if (json[key] === undefined) return '';
    return encodeURIComponent(key) + '=' + encodeURIComponent(json[key]);
  })).join('&');
}
export function param2Obj(url) {
  var search = url.split('?')[1];
  if (!search) {
    return {};
  }
  return JSON.parse('{"' + decodeURIComponent(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"').replace(/\+/g, ' ') + '"}');
}
export function html2Text(val) {
  var div = document.createElement('div');
  div.innerHTML = val;
  return div.textContent || div.innerText;
}
export function objectMerge(target, source) {
  /* Merges two  objects,
     giving the last one precedence */

  if (_typeof(target) !== 'object') {
    target = {};
  }
  if (Array.isArray(source)) {
    return source.slice();
  }
  Object.keys(source).forEach(function (property) {
    var sourceProperty = source[property];
    if (_typeof(sourceProperty) === 'object') {
      target[property] = objectMerge(target[property], sourceProperty);
    } else {
      target[property] = sourceProperty;
    }
  });
  return target;
}
export function toggleClass(element, className) {
  if (!element || !className) {
    return;
  }
  var classString = element.className;
  var nameIndex = classString.indexOf(className);
  if (nameIndex === -1) {
    classString += '' + className;
  } else {
    classString = classString.substr(0, nameIndex) + classString.substr(nameIndex + className.length);
  }
  element.className = classString;
}
export var pickerOptions = [{
  text: '今天',
  onClick: function onClick(picker) {
    var end = new Date();
    var start = new Date(new Date().toDateString());
    end.setTime(start.getTime());
    picker.$emit('pick', [start, end]);
  }
}, {
  text: '最近一周',
  onClick: function onClick(picker) {
    var end = new Date(new Date().toDateString());
    var start = new Date();
    start.setTime(end.getTime() - 3600 * 1000 * 24 * 7);
    picker.$emit('pick', [start, end]);
  }
}, {
  text: '最近一个月',
  onClick: function onClick(picker) {
    var end = new Date(new Date().toDateString());
    var start = new Date();
    start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
    picker.$emit('pick', [start, end]);
  }
}, {
  text: '最近三个月',
  onClick: function onClick(picker) {
    var end = new Date(new Date().toDateString());
    var start = new Date();
    start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
    picker.$emit('pick', [start, end]);
  }
}];
export function getTime(type) {
  if (type === 'start') {
    return new Date().getTime() - 3600 * 1000 * 24 * 90;
  } else {
    return new Date(new Date().toDateString());
  }
}
export function debounce(func, wait, immediate) {
  var timeout, args, context, timestamp, result;
  var _later = function later() {
    // 据上一次触发时间间隔
    var last = +new Date() - timestamp;

    // 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait
    if (last < wait && last > 0) {
      timeout = setTimeout(_later, wait - last);
    } else {
      timeout = null;
      // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
      if (!immediate) {
        result = func.apply(context, args);
        if (!timeout) context = args = null;
      }
    }
  };
  return function () {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }
    context = this;
    timestamp = +new Date();
    var callNow = immediate && !timeout;
    // 如果延时不存在，重新设定延时
    if (!timeout) timeout = setTimeout(_later, wait);
    if (callNow) {
      result = func.apply(context, args);
      context = args = null;
    }
    return result;
  };
}

/**
 * This is just a simple version of deep copy
 * Has a lot of edge cases bug
 * If you want to use a perfect deep copy, use lodash's _.cloneDeep
 */
export function deepClone(source) {
  if (!source && _typeof(source) !== 'object') {
    throw new Error('error arguments', 'deepClone');
  }
  var targetObj = source.constructor === Array ? [] : {};
  Object.keys(source).forEach(function (keys) {
    if (source[keys] && _typeof(source[keys]) === 'object') {
      targetObj[keys] = deepClone(source[keys]);
    } else {
      targetObj[keys] = source[keys];
    }
  });
  return targetObj;
}
export function uniqueArr(arr) {
  return Array.from(new Set(arr));
}
export function createUniqueString() {
  var timestamp = +new Date() + '';
  var randomNum = parseInt((1 + Math.random()) * 65536) + '';
  return (+(randomNum + timestamp)).toString(32);
}
export function hasClass(ele, cls) {
  return !!ele.className.match(new RegExp('(\\s|^)' + cls + '(\\s|$)'));
}
export function addClass(ele, cls) {
  if (!hasClass(ele, cls)) ele.className += ' ' + cls;
}
export function removeClass(ele, cls) {
  if (hasClass(ele, cls)) {
    var reg = new RegExp('(\\s|^)' + cls + '(\\s|$)');
    ele.className = ele.className.replace(reg, ' ');
  }
}
export function renderTime(date) {
  if (!date) return null;
  var dateee = new Date(date).toJSON();
  return new Date(+new Date(dateee) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '');
}
export function moneyFormat(value) {
  if (!value) return '0.00';
  /*原来用的是Number(value).toFixed(0)，这样取整时有问题，例如0.51取整之后为1，感谢Nils指正*/
  var intPart = Number(value) | 0;
  //获取整数部分
  var intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
  //将整数部分逢三一断
  var floatPart = ".00";
  //预定义小数部分
  var value2Array = value.toString().split(".");
  //=2表示数据有小数位
  if (value2Array.length == 2) {
    floatPart = value2Array[1].toString();
    //拿到小数部分
    if (floatPart.length == 1) {
      //补0,实际上用不着
      return intPartFormat + "." + floatPart + '0';
    } else {
      return intPartFormat + "." + floatPart;
    }
  } else {
    return intPartFormat + floatPart;
  }
}
export function betwenMinutes(time1, time2) {
  var d = new Date(time1);
  var now = new Date(time2);
  var diff = (d - now) / 1000;
  return Math.ceil(diff / 60) + '分钟';
}