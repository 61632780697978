import variables from '@/styles/element-variables.scss';
export default {
  theme: variables.theme,
  // theme: localStorage.getItem("theme") ? localStorage.getItem("theme") : variables.theme,

  /**
   * @type {boolean} true | false
   * @description Whether show the settings right-panel
   */
  showSettings: false,
  /**
   * @type {boolean} true | false
   * @description Whether need tagsView
   */
  tagsView: true,
  // tagsView: localStorage.getItem("tagsView"),

  /**
   * @type {boolean} true | false
   * @description Whether fix the header
   */
  fixedHeader: true,
  // fixedHeader: localStorage.getItem("fixedHeader") ? localStorage.getItem("fixedHeader") : true,

  /**
   * @type {boolean} true | false
   * @description Whether show the logo in sidebar
   */
  sidebarLogo: true,
  // sidebarLogo: localStorage.getItem("sidebarLogo") ? localStorage.getItem("sidebarLogo") : true,

  /**
   * @type {string | array} 'production' | ['production','development']
   * @description Need show err logs component.
   * The default is only used in the production env
   * If you want to also use it in dev, you can pass ['production','development']
   */
  errorLog: 'production'
};