import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es6.regexp.match";
import "core-js/modules/es6.regexp.replace";
import _objectSpread from "/root/workspace/tk-shop_1BXA/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Settings from "@/layout/components/Settings";
import ThemePicker from "@/components/ThemePicker";
import { mapState } from "vuex";
export default {
  name: "App",
  provide: function provide() {
    return {
      reload: this.reload
    };
  },
  components: {
    Settings: Settings,
    ThemePicker: ThemePicker
  },
  computed: _objectSpread({
    defaultTheme: function defaultTheme() {
      return this.$store.state.settings.theme;
    }
  }, mapState({
    theme_color: function theme_color(state) {
      return state.settings.theme;
    },
    tieme_style: function tieme_style(state) {
      return state.settings.tieme_style;
    },
    counter: function counter(state) {
      return state.settings.counter;
    }
  })),
  data: function data() {
    return {
      color: "",
      tieme_style_color: "",
      font_style_color: "",
      isRouterAlive: true
    };
  },
  mounted: function mounted() {
    localStorage.setItem("allorder-loading", 1);
    localStorage.setItem("goods_list", "");
    localStorage.setItem("selectedOrderIds", "");
  },
  created: function created() {
    this.theme();
    this.add_tieme_style();
    this.add_counter();
    // let color = "#333";
    // this.setThemeColor(color);
  },
  watch: {
    theme_color: function theme_color(old_color, new_color) {
      this.theme();
      console.log("1121212", old_color, new_color);
    },
    tieme_style: function tieme_style(old_color, new_color) {
      this.add_tieme_style();
      console.log("333333", old_color, new_color);
    },
    counter: function counter(old_color, new_color) {
      this.add_counter();
      console.log("333333", old_color, new_color);
    }
  },
  methods: {
    reload: function reload() {
      console.log("触发reload函数");
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    },
    add_counter: function add_counter() {
      var counter = this.counter + "px";
      document.body.style.setProperty("--counter", counter);
    },
    add_tieme_style: function add_tieme_style() {
      if (this.tieme_style == 1) {
        this.tieme_style_color = "#1d2124";
        this.font_style_color = "#ffffff";
        document.body.style.setProperty("--tiemeStyle", this.tieme_style_color);
        document.body.style.setProperty("--fontStyle", this.font_style_color);
      } else {
        this.tieme_style_color = "#ffffff";
        this.font_style_color = "#1d2124";
        document.body.style.setProperty("--tiemeStyle", this.tieme_style_color);
        document.body.style.setProperty("--fontStyle", this.font_style_color);
      }
    },
    theme: function theme() {
      var color = this.lighten(this.theme_color, 0.9);
      this.color = color;
      document.body.style.setProperty("--primary", color);
      console.log("获取的颜色111111", this.theme_color, color);
    },
    // 使颜色变亮
    lighten: function lighten(color, level) {
      var rgb = this.hex2Rgb(color);
      for (var i = 0; i < 3; i++) {
        rgb[i] = Math.floor((255 - rgb[i]) * level + rgb[i]);
      }
      var result = this.rgb2Hex(rgb[0], rgb[1], rgb[2]);
      return result;
    },
    //  使颜色变暗
    darken: function darken(color, level) {
      var rgb = this.hex2Rgb(color);
      for (var i = 0; i < 3; i++) {
        rgb[i] = Math.floor(rgb[i] * (1 - level));
      }
      var result = this.rgb2Hex(rgb[0], rgb[1], rgb[2]);
      return result;
    },
    hex2Rgb: function hex2Rgb(color) {
      color = color.replace("#", "");
      var result = color.match(/../g);
      for (var i = 0; i < 3; i++) {
        result[i] = parseInt(result[i], 16);
      }
      return result;
    },
    rgb2Hex: function rgb2Hex(r, g, b) {
      var hexs = [r.toString(16), g.toString(16), b.toString(16)];
      for (var i = 0; i < 3; i++) {
        if (hexs[i].length === 1) {
          hexs[i] = "0" + hexs[i];
        }
      }
      var result = "#" + hexs.join("");
      return result;
    },
    themeChange: function themeChange(val) {
      this.$store.dispatch("settings/changeSetting", {
        key: "theme",
        value: val
      });
    },
    tagsViewChange: function tagsViewChange(val) {
      this.$store.dispatch("settings/changeSetting", {
        key: "tagsView",
        value: val
      });
    },
    fixedHeaderChange: function fixedHeaderChange(val) {
      this.$store.dispatch("settings/changeSetting", {
        key: "fixedHeader",
        value: val
      });
    },
    sidebarLogoChange: function sidebarLogoChange(val) {
      this.$store.dispatch("settings/changeSetting", {
        key: "sidebarLogo",
        value: val
      });
    }
  }
};