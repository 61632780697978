import "core-js/modules/es6.regexp.replace";
// translate router.meta.title, be used in breadcrumb sidebar tagsview
export function generateTitle(title, args) {
  var hasKey = this.$te('route.' + title);
  if (hasKey) {
    // $t :this method from vue-i18n, inject in @/lang/index.js
    var translatedTitle = this.$t('route.' + title);
    return formatTemplate(translatedTitle, args);
  }
  return title;
}

// 动态替换模板字符串中的占位符
function formatTemplate(template, args) {
  //template="姓名: ${0}, 年龄: ${1}, 城市: ${2}, 职业: ${3}"
  return template.replace(/\${(\d+)}/g, function (match, index) {
    return args[index];
  });
}