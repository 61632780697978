import Layout from '@/views/client/layout';
var clientRouter = {
  path: '/client',
  component: Layout,
  name: 'client',
  hidden: true,
  children: [{
    path: 'product',
    component: function component() {
      return import('@/views/client/product/index.vue');
    },
    name: 'clientProduct'
  }, {
    path: 'info',
    component: function component() {
      return import('@/views/client/product/info.vue');
    },
    name: 'clientProductInfo'
  }]
};
export default clientRouter;