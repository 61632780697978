import _objectSpread from "/root/workspace/tk-shop_1BXA/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";
import "core-js/modules/es6.function.name";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import path from "path";
import { generateTitle } from "@/utils/i18n";
import { isExternal } from "@/utils/validate";
import Item from "./Item";
import AppLink from "./Link";
import FixiOSBug from "./FixiOSBug";
export default {
  name: "SidebarItem",
  components: {
    Item: Item,
    AppLink: AppLink
  },
  mixins: [FixiOSBug],
  props: {
    // route object
    item: {
      type: Object,
      required: true
    },
    isNest: {
      type: Boolean,
      default: false
    },
    basePath: {
      type: String,
      default: ""
    }
  },
  data: function data() {
    // To fix https://github.com/PanJiaChen/vue-admin-template/issues/237
    // TODO: refactor with render function
    this.onlyOneChild = null;
    return {
      title: this.$store.state.user.name.substr(0, 2)
    };
  },
  methods: {
    hasOneShowingChild: function hasOneShowingChild() {
      var _this = this;
      var children = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      var parent = arguments.length > 1 ? arguments[1] : undefined;
      var showingChildren = children.filter(function (item) {
        if (item.hidden) {
          return false;
        } else {
          // Temp set(will be used if only has one showing child)
          _this.onlyOneChild = item;
          // console.log("this.onlyOneChild", this.onlyOneChild);
          return true;
        }
      });

      // When there is only one child router, the child router is displayed by default
      if (showingChildren.length === 1) {
        return true;
      }

      // Show parent if there are no child router to display
      if (showingChildren.length === 0) {
        this.onlyOneChild = _objectSpread(_objectSpread({}, parent), {}, {
          path: "",
          noShowingChildren: true
        });
        return true;
      }
      return false;
    },
    resolvePath: function resolvePath(routePath) {
      if (isExternal(routePath)) {
        return routePath;
      }
      return path.resolve(this.basePath, routePath);
    },
    generateTitle: generateTitle
  }
};