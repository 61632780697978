import "core-js/modules/es6.regexp.to-string";
/**
 * Created by jiachenpan on 16/11/18.
 */

export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path);
}
export function validUsername(str) {
  return true;
}
export function validURL(url) {
  var reg = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/;
  return reg.test(url);
}
export function validLowerCase(str) {
  var reg = /^[a-z]+$/;
  return reg.test(str);
}
export function validUpperCase(str) {
  var reg = /^[A-Z]+$/;
  return reg.test(str);
}
export function validAlphabets(str) {
  var reg = /^[A-Za-z]+$/;
  return reg.test(str);
}
export function validEmail(email) {
  var reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return reg.test(email);
}
export function isString(str) {
  if (typeof str === 'string' || str instanceof String) {
    return true;
  }
  return false;
}
export function isArray(arg) {
  if (typeof Array.isArray === 'undefined') {
    return Object.prototype.toString.call(arg) === '[object Array]';
  }
  return Array.isArray(arg);
}
export function validPhone(str) {
  return !/^1\d{10}$/.test(str);
}
export function validateNumber(rule, value, callback) {
  var reg = /^-?\d+(\.\d+)?$/;
  if (reg.test(value)) {
    callback();
  } else {
    callback(new Error('请输入有效的正数或负数'));
  }
}